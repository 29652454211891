import PropTypes from 'prop-types';
import { YouTubeEmbed } from '@next/third-parties/google';

// utils
import { cn } from 'utils';
// Styles
import styles from './YoutubePlayer.module.css';

function YoutubePlayer({ videoId, className }) {
  return (
    <div className={cn([styles.wrapper, className])}>
      <YouTubeEmbed
        height={480}
        videoid={videoId}
        title="Youtube video player"
        className={styles.player}
      />
    </div>
  );
}

YoutubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

YoutubePlayer.defaultProps = {
  className: null,
};

export default YoutubePlayer;
